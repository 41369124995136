import { useEffect, useState } from "react";
import { checkArray } from "./checkArray";
import { useAuth } from "../services/AuthContext";

const getPermissionCodes = (permissions) => {
  return checkArray(permissions)
    ? permissions.map((el) => {
        switch (el) {
          case "Admin":
            return "MA";
          case "User":
            return "A";
          case "UserRead":
            return "R";
          case "UserCreate":
            return "C";
          case "UserUpdate":
            return "U";
          case "UserDelete":
            return "D";
        }
      })
    : null;
};

export const useCheckPermission = (permissions, model) => {
  const { auth } = useAuth();
  const [allow, setAllow] = useState(null);
  useEffect(() => {
    if (auth) {
      const codes = getPermissionCodes(permissions);
      const checkAllow = checkArray(codes)
        ? codes.every((el) => auth.access[model][el])
        : false;
      setAllow(checkAllow);
    }
  }, [auth, model, permissions]);
  return allow;
};
