import React, { useRef, useState } from "react";
import { Box } from "@mui/material";

import Toolbar from "../components/toolbar";
import Calendar from "../components/calendar";
import { useCheckPermission } from "../utils/useCheckPermission";
import EventForm from "../components/eventForm";
import { useAuth } from "../services/AuthContext";
import EventInfo from "../components/eventInfo";

const eventTypes = [
  "group",
  "holiday",
  "meeting",
  "social",
  "external",
  "personal",
  "other",
];

export default function Home() {
  const { auth } = useAuth();
  const calendarRef = useRef(null);
  const isAdmin = useCheckPermission(["Admin"], "calendar");
  const canUpdate = useCheckPermission(
    ["UserUpdate", "UserDelete"],
    "calendar"
  );

  const mapArr = eventTypes.map((el) => [el, true]);
  const defaultTypes = new Map(mapArr);

  const [type, setType] = useState(defaultTypes);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [update, setUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);

  const allowEventCrud =
    (canUpdate && currentEvent?.userRef === auth.id) || isAdmin;

  return (
    <Box>
      <Toolbar
        calendarRef={calendarRef}
        eventTypes={eventTypes}
        type={type}
        setType={setType}
        onAddEvent={() => setOpen(true)}
      />
      <Calendar
        ref={calendarRef}
        eventTypes={eventTypes}
        type={type}
        setCurrentEvent={setCurrentEvent}
        update={update}
        onEventClick={() => setOpen(true)}
        onEventClickRead={() => setInfoOpen(true)}
        allowEventCrud={allowEventCrud}
      />
      <EventForm
        open={open}
        setOpen={setOpen}
        currentEvent={currentEvent}
        setCurrentEvent={setCurrentEvent}
        eventTypes={eventTypes}
        setUpdate={setUpdate}
      />
      <EventInfo
        open={infoOpen}
        setOpen={setInfoOpen}
        currentEvent={currentEvent}
      />
    </Box>
  );
}
