export const getEventColor = (event) => {
  const eventColors = new Map([
    ["group", "#3f51b5"],
    ["holiday", "#43a047"],
    ["meeting", "#039be5"],
    ["social", "#f44336"],
    ["external", "#9575cd"],
    ["personal", "#f57c00"],
    ["other", "#4db6ac"],
  ]);
  return eventColors.get(event) || "#e0e0e0";
};
