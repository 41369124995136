import { EmailApi } from "@unity/components";

export const sendEmail = async (
  app_uuid,
  email = { subject: "", body: "", to: "" }
) => {
  const mailInfo = await EmailApi.getApplicationMailer(app_uuid);
  if (mailInfo && mailInfo.success) {
    const { id, from_address } = mailInfo.data;
    const obj = {
      smtp_id: id || null,
      callFrom: from_address,
      subject: email?.subject || "",
      body: email?.body || "",
      to: email?.to || "",
    };
    const res = await EmailApi.sendMail(obj);
    return res;
  }
};
