import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { CalendarApi, PersonnelApi } from "@unity/components";
import { checkArray } from "../utils/checkArray";
import { useAuth } from "../services/AuthContext";

const EventItem = ({ primary, secondary, isDate, loading }) => {
  const test = isDate && secondary ? secondary.toString() : null;
  return (
    <ListItem>
      <ListItemText
        primary={primary || ""}
        secondary={
          loading ? <CircularProgress /> : isDate ? test : secondary || ""
        }
      />
    </ListItem>
  );
};

export default function EventInfo({ open, setOpen, currentEvent }) {
  const { auth } = useAuth();
  const [attendees, setAttendees] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAttendees = async () => {
      setLoading(true);
      const res = await CalendarApi.getEventsByFilter({
        instance_uuid: currentEvent.instance_uuid,
      });
      let apiPromises = checkArray(res.data)
        ? res.data
            .filter((el) => el.contact_id !== auth.id)
            .map((el) => PersonnelApi.getPersonnelSingle(el.contact_id))
        : [];
      let localAttendees = [];
      Promise.all(apiPromises).then((res) => {
        if (checkArray(res)) {
          res.forEach((el) => {
            if (el && el.data) localAttendees.push(el.data);
          });
          console.log("ATTENDEES: ", localAttendees);
          setAttendees(localAttendees);
          setLoading(false);
        }
      });
    };
    if (currentEvent?.instance_uuid) getAttendees();
  }, [currentEvent?.instance_uuid]);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent>
        <List>
          <EventItem primary="Title" secondary={currentEvent?.subject} />
          <EventItem primary="Type" secondary={currentEvent?.type} />
          <EventItem
            primary="Description"
            secondary={currentEvent?.description}
          />
          <EventItem primary="Start" secondary={currentEvent?.start} isDate />
          <EventItem primary="End" secondary={currentEvent?.end} isDate />
          <EventItem
            primary="Attendees"
            loading={loading}
            secondary={attendees.map((el) => el.name).toString(", ")}
          />
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
